<script setup lang="ts">
    import { computed, ref } from 'vue';
    const props = defineProps({
        tag: {
            type: String,
            default: 'button',
        },
        type: {
            type: String,
            default: 'button',
        },
        href: {
            type: String,
            default: null,
        },
        lang: {
            type: String,
            default: null,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        ripple: {
            type: Boolean,
            default: false,
        },
        noGmt: {
            type: Boolean,
            default: false,
        },
        gmt: {
            type: Object,
            default: () => ({}),
        },
        primary: {
            type: Boolean,
            default: false,
        },
        dark: {
            type: Boolean,
            default: false,
        },
        light: {
            type: Boolean,
            default: false,
        },
        secondary: {
            type: Boolean,
            default: false,
        },
        woom: {
            type: Boolean,
            default: false,
        },
        accent: {
            type: Boolean,
            default: false,
        },
        block: {
            type: Boolean,
            default: false,
        },
        to: String,
        icon: String,
        iconLeft: String,
        iconRight: String,
        /**
         * remove the color version and only apply on the icon itself
         */
        noColor: Boolean,
        text: Boolean,
        // if it's a trigger button
        trigger: Boolean,
    });

    /**
     * :class="[
      'w-button',
      auto || block ? 'w-button-width-auto' : 'w-button-width-default',
    ]"
     */

    const attrs = useAttrs();
    const htmlTag = computed(() => (attrs.href ? 'a' : props.to ? 'router-link' : props.tag));
    const elRef = ref<HTMLElement | null>(null);

    const computedWoom = computed(() => props.woom);
    const computedPrimary = computed(() => props.primary);
    const computedSecondary = computed(() => props.secondary);
    const computedAccent = computed(() => props.accent);
    const computedBlock = computed(() => props.block);
    const computedRipple = computed(() => props.ripple);
    const computedDark = computed(() => props.dark);
    const computedLight = computed(() => props.light);
    const computedDisabled = computed(() => props.disabled);

    const loading = ref(props.loading);
    const tag = ref(props.tag);
    const to = ref(props.to);
    const href = ref(props.href);
    const lang = ref(props.lang);

    const woomClasses = computed(() => {
        const ret = [];
        if (computedWoom.value) {
            ret.push('woom-button');
        }
        if (computedDark.value) {
            ret.push('woom-dark');
            ret.push('white');
            ret.push('black--text');
        } else if (computedLight.value) {
            ret.push('woom-light');
            ret.push('black');
            ret.push('white--text');
        }
        return ret;
    });

    const hrefAttr = computed(() => {
        // console.log('hrefAttr', isNuxt.value, href.value);
        return isNuxt.value ? href.value : undefined;
    });

    const isNuxt = computed(() => {
        // console.log('isNuxt', to.value);
        if (to.value) {
            return true;
        } else if (attrs && attrs.to) {
            return true;
        }
        return href.value && href.value.indexOf('/') === 0;
    });

    const toAttr = computed(() => {
        // console.log('toAttr', to.value, attrs.to);
        if (to.value) {
            return to.value;
        } else if (attrs && attrs.to) {
            return attrs.to;
        }
        return isNuxt.value ? href.value : undefined;
    });

    const isBlock = computed(() => {
        // console.log('isBlock', computedBlock.value);
        if (computedBlock.value) {
            return `display: flex; flex: auto; min-width: 100% !important; max-width: none`;
        }
    });

    // TODO: update data type for click event
    const emit = defineEmits<{
        (e: 'click', data: any): void;
    }>();

    const handleClick = (evt: any) => {
        // console.log('handleClick ~ click evt', evt);
        emit('click', evt);
    };
</script>

<template>
    <component
        :is="htmlTag"
        ref="elRef"
        :to="toAttr"
        :href="hrefAttr"
        :type="type"
        :disabled="computedDisabled || loading"
        class="inline-flex relative box-border justify-center"
        :class="woomClasses"
        :aria-busy="loading ? 'true' : null"
        :aria-disabled="tag !== 'button' && computedDisabled ? 'true' : null"
        :style="isBlock"
        :ripple="computedRipple"
        :block="computedBlock"
        :nuxt="isNuxt"
        @click="handleClick">
        <template v-if="lang">
            <span>{{ $t(lang) }}</span>
        </template>
        <slot></slot>
    </component>
</template>

<style scoped>
    .woom-button {
        @apply transition-transform duration-[0.1] ease-in-out cursor-pointer outline-0 whitespace-nowrap font-bold;
        /* min-width: 280px !important; */
        /* -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none; */
    }
    .woom-button:hover,
    .woom-button:focus {
        transform: scale(1.05);
    }
    .woom-button:active {
        transform: scale(1);
    }

    @media only screen and (min-width: 360px) {
        .w-button-width-default:not(.v-btn--fab) {
            min-width: 280px !important;
        }
    }
</style>
